import { VERSION } from '~/modules/config';
import { UserTokenService } from '~/modules/services/userTokenService';
import { featureFlagsStore } from '~/modules/stores/feature_flags_store';

console.log(
    `AppExchange v${VERSION} (built on ${new Date(
        bundledConfig.BUILD_TIMESTAMP,
    ).toLocaleString()}) © Copyright ${new Date().getFullYear()} Salesforce, Inc.`,
);

// Hydrate feature flags
featureFlagsStore.getFeatureFlags({
    defaultValues: window.stores?.FEATURE_FLAGS,
    context: {
        userId: UserTokenService.getUserToken(),
        hostname: window.location.hostname,
    },
});

// We must use dynamic import here to ensure feature flags are fully loaded before the client initializes
import('./boot_client');
